<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros relatórios</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="3" class="mb-1">
            <b-form-group
              label="Mês/Fatura"
              label-for="searchReportsDate"
            >
              <flat-pickr
                id="searchReportsDate"
                v-model="reportsDate"
                class="form-control"
                :config="flatPickrConfigInvoice"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-button
              variant="primary"
              @click.prevent="downloadPaymentCommissionsReport"
              :disabled="loading.paymentCommissions"
              class="mr-2"
            >
              <b-spinner v-if="loading.paymentCommissions" small />
              <feather-icon v-else icon="DownloadIcon" class="mr-50" />
              <span>
                {{ loading.paymentCommissions ? "Aguarde..." : "Analítico de comissões detalhado" }}
              </span>
            </b-button>
            <b-button
              variant="primary"
              @click.prevent="downloadInvoiceSummary"
              :disabled="loading.invoiceSummary"
              class="mr-2"
            >
              <b-spinner v-if="loading.invoiceSummary" small />
              <feather-icon v-else icon="DownloadIcon" class="mr-50" />
              <span>
                {{ loading.invoiceSummary ? "Aguarde..." : "Resumo de faturamento" }}
              </span>
            </b-button>
            <b-button
              variant="primary"
              @click.prevent="downloadAgencyCommissions"
              :disabled="loading.agencyCommissions"
              class="mr-2"
            >
              <b-spinner v-if="loading.agencyCommissions" small />
              <feather-icon v-else icon="DownloadIcon" class="mr-50" />
              <span>
                {{ loading.agencyCommissions ? "Aguarde..." : "Comissões de agenciamento" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros busca</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-1">
            <b-form-group
              label="Parceiro de negócio (PN)"
              label-for="searchConsultant"
            > 
              <v-select
                id="searchConsultant"
                v-model="consultant"
                :reduce="consultant_list => consultant_list.id"
                :options="consultantList"
                :loading="loadingConsultants"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mb-1">
            <b-form-group
              label="Período"
              label-for="searchRangeDate"
            >
              <flat-pickr
                id="searchRangeDate"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mb-1">
            <b-form-group
              label="Mês/Fatura"
              label-for="searchInvoiceDate"
            >
              <flat-pickr
                id="searchInvoiceDate"
                v-model="invoiceDate"
                class="form-control"
                :config="flatPickrConfigInvoice"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-primary"
              @click.prevent="search"
              :disabled="loading.search"
            >
              <b-spinner v-if="loading.search" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.search ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="history">
      <div class="m-2">
        <!-- Table Top -->
        <h6>ID: {{ history.consultant_id }}</h6> 
      
        <h6>Nome: {{ history.consultant_name }}</h6>
      
        <h6>Email: {{ history.consultant_email }}</h6>
        
        <h6>Status: {{ history.consultant_staus }}</h6>
        <div class="mt-2">
          <h4>Ajustes</h4>
          <b-table hover class="position-relative" responsive :items="history.financial_adjustments"></b-table>
        </div>
        <div class="mt-2">
          <h4>Faturas importadas manualmente</h4>
          <b-table hover class="position-relative" responsive :items="history.imports"></b-table>
        </div>
        <div class="mt-2">
          <h4>Faturas do sistema</h4>
          <b-table hover class="position-relative" responsive :items="history.invoices"></b-table>
        </div>
        <div class="mt-2">
          <h4>Solicitações de saque</h4>
          <b-table hover class="position-relative" responsive :items="history.withdrawal_requests"></b-table>
        </div>
        <div class="mt-2">
          <h4>Saques</h4>
          <b-table hover class="position-relative" responsive :items="history.withdrawals"></b-table>
        </div>
      </div>
      
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { getRangeDates } from "@/helpers/date_picker";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import useAppConfig from "@core/app-config/useAppConfig";
import * as reportTypes from "@/modules/report/store/types";



export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    flatPickr
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear, skin };
  },
  data() {
    return {
      loading: {
        search: false,
        invoiceSummary: false,
        paymentCommissions: false,
        agencyCommissions: false
      },
      flatPickrConfig:{
        altFormat: 'j M Y',
        altInput: true,
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: Portuguese,
      },
      flatPickrConfigInvoice: {
        altFormat: 'j M Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Portuguese,
        maxDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1, 
            new Date().getDate()
          ),
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'Y-m', 
            altFormat: "F Y",
            theme: this.skin
          })
        ] 
      },
      loadingConsultants: false,
      consultant: undefined,
      rangeDate: undefined,
      invoiceDate: undefined,
      history: undefined,
      reportsDate: undefined
    };
  },
  computed: {
    ...mapGetters({
      consultantList: sharedTypes.CONSULTANTS,
    })
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.loadingConsultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os usuários para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingConsultants = false;
      });
  },
  methods: {
    ...mapActions({
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getInvoiceHistory: types.GET_INVOICE_HISTORY,
      exportPaymentCommissions: reportTypes.EXPORT_CONTRACTS_PAYMENTS_COMISSIONS,
      exportAgencyCommissions: reportTypes.EXPORT_AGENCY_COMMISSIONS,
      exportInvoiceSummary: reportTypes.EXPORT_INVOICES_SUMMARY
    }),
    search() {
      if(!this.consultant){
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "É necessário selecionar um consultor para realizar a busca.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return
      }
      this.loading.search = true;
      const date = getRangeDates(this.rangeDate);
      this.getInvoiceHistory({
        consultant_id: this.consultant,
        start_date: date.start,
        end_date: date.end, 
        invoice_date: this.invoiceDate
      })
        .then((resp) => {
          if(resp.data){
            this.history = resp.data
          }
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os detalhes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.search = false;
        });
    },
    validateReportFilter(){
      if(!this.reportsDate){
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "É necessário selecionar um mês para baixar os relatórios.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return false
      }
      return true
    },
    downloadPaymentCommissionsReport(){
      if (!this.validateReportFilter()) return
      this.loading.paymentCommissions = true
      const month = parseInt(this.reportsDate.split('-')[1])
      const year = parseInt(this.reportsDate.split('-')[0])
      this.exportPaymentCommissions({
        month,
        year
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o relatório. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.paymentCommissions = false
        })
    },
    downloadInvoiceSummary(){
      if (!this.validateReportFilter()) return
      this.loading.invoiceSummary = true
      const month = parseInt(this.reportsDate.split('-')[1])
      const year = parseInt(this.reportsDate.split('-')[0])
      this.exportInvoiceSummary({
        month,
        year
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o relatório. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.invoiceSummary = false
        })
    },
    downloadAgencyCommissions(){
      if (!this.validateReportFilter()) return
      this.loading.agencyCommissions = true
      const month = parseInt(this.reportsDate.split('-')[1])
      const year = parseInt(this.reportsDate.split('-')[0])
      this.exportAgencyCommissions({
        month,
        year
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o relatório. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.agencyCommissions = false
        })
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
</style>
